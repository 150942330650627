import Container from '@/component/Container';
import { useBreadcrumb } from '@/context';
import { useState } from 'react';
import { useDebounce } from 'react-use';
import { StyledBreadcrumb } from './Breadcrumb.style';
import { BreadcrumbItem } from './BreadcrumbItem';

type BreadcrumbDto = {
  key: string;
  path?: string;
  label: string;
};

export function Breadcrumb() {
  const { items } = useBreadcrumb();
  const [debouncedItems, setDebouncedItems] = useState<BreadcrumbDto[]>(items);

  useDebounce(
    () => {
      setDebouncedItems(items);
    },
    50,
    [items],
  );

  return (
    <Container>
      <StyledBreadcrumb>
        {debouncedItems.map((item) => (
          <BreadcrumbItem key={item.key} item={item} />
        ))}
      </StyledBreadcrumb>
    </Container>
  );
}
