import { useListOrders } from '@/api/order/list-orders';
import { OrderStatus } from '@/enum';
import { I18nNs } from '@/util/i18n';
import HistoryIcon from '@mui/icons-material/History';
import { Chip, Stack, Tab } from '@mui/material';
import Typography from '@mui/material/Typography';
import { ForwardedRef, forwardRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ETab } from '../enum';

type Props = {
  value: ETab;
  selected?: boolean;
};

export const TabLast = forwardRef((props: Props, ref: ForwardedRef<any>) => {
  const { t } = useTranslation(I18nNs.Dashboard);
  const { data } = useListOrders({
    status: [
      OrderStatus.Incomplete,
      OrderStatus.Production,
      OrderStatus.Valid,
      OrderStatus.Delivery,
    ],
    limit: 1,
  });
  const totalDocs = useMemo(() => data?.totalDocs || 0, [data?.totalDocs]);

  return (
    <Tab
      ref={ref}
      {...props}
      icon={<HistoryIcon />}
      iconPosition="start"
      value={ETab.Last}
      label={
        <Stack direction="row">
          <Typography variant="inherit">{t('tab.last')}</Typography>
          {totalDocs > 0 && (
            <Chip
              size="small"
              sx={{ ml: 1, opacity: props.selected ? 1 : 0.7 }}
              label={totalDocs}
              color="primary"
            />
          )}
        </Stack>
      }
    />
  );
});
