import { useGetSaleRollingYearByWeek } from '@/api/stat/get-sale-rolling-year-by-week';
import { I18nNs } from '@/util/i18n';
import { price } from '@/util/price';
import { Paper, Skeleton } from '@mui/material';
import { ApexOptions } from 'apexcharts';
import { useMemo, useState } from 'react';
import Chart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import { useKey } from '../../hook/useKey';
import { StatNoData } from '../StatNoData/StatNoData';

type Props = {
  id: string;
};

export function StatSaleRollingYearByMonth({ id }: Props) {
  const { data, isLoading } = useGetSaleRollingYearByWeek();
  const { t } = useTranslation(I18nNs.Stat);
  const [noData, setNoData] = useState<boolean>(false);

  const key = useKey();

  const series: ApexOptions['series'] = useMemo(() => {
    if (!Array.isArray(data)) return [];
    return [
      {
        data: data.map((d) => d.ca),
      },
    ];
  }, [data]);

  const options: ApexOptions | null = useMemo(() => {
    if (!Array.isArray(data)) return null;
    if (data.length === 0) {
      setNoData(true);
      return null;
    }

    return {
      chart: {
        id,
        // group: 'sparklines',
        height: 160,
        sparkline: {
          enabled: true,
        },
      },
      stroke: {
        curve: 'smooth',
      },
      fill: {
        opacity: 0.3,
      },
      colors: ['#f25e2f'],
      xaxis: {
        categories: data.map((d) => d.week),
        crosshairs: {
          width: 1,
        },
      },
      yaxis: {
        min: 0,
        labels: {
          show: false,
          formatter: (val: number) => price(val, { currency: data[0].currency }),
        },
      },
      dataLabels: {
        formatter: (val: number) => price(val, { currency: data[0].currency }),
      },
      tooltip: {
        y: {
          title: {
            formatter: () => '',
          },
        },
      },
      title: {
        text: t('saleByWeek'),
        offsetX: 0,
        style: {
          fontSize: '24px',
        },
      },
    };
  }, [data, t, id]);

  if (noData) return <StatNoData />;

  if (!options || isLoading) return <Skeleton variant="rectangular" height={160} />;
  if (options?.colors?.length === 0) return <Skeleton variant="rectangular" height={160} />;

  return (
    <Paper key={key} elevation={3} sx={{ height: 160, position: 'relative' }}>
      <Chart options={options} series={series} type="area" height={160} />
    </Paper>
  );
}
