import { axios } from '@/util/axios';
import { getUrl } from '@/util/get-url';
import { handleError } from '@/util/handle-error';
import swr, { SWRResponse } from 'swr';
import { ListOrdersReadyForDeliveryOutput } from './list-orders-ready-for-delivery.output.ts';

const path = '/delivery-helper/order-ready';

export const listOrdersReadyForDelivery = async (): Promise<ListOrdersReadyForDeliveryOutput> => {
  try {
    const url = getUrl(path);
    return await axios.get<ListOrdersReadyForDeliveryOutput>(url).then((res) => res.data);
  } catch (err) {
    return handleError(path, err);
  }
};

export const useListOrdersReadyForDelivery = (): SWRResponse<ListOrdersReadyForDeliveryOutput> => {
  const url = getUrl(path);
  return swr<ListOrdersReadyForDeliveryOutput>(url, listOrdersReadyForDelivery);
};
