import { getMe } from '@/api/auth/get-me';
import { updateMe } from '@/api/auth/update-me';
import { useLang } from '@/hook';
import { useSetUser, useUserId, useUserLang } from '@/hook/user';
import { I18nNs } from '@/util/i18n';
import { Box, ButtonBase, Tooltip } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'react-use';

type Props = {
  className?: string;
};

export function Lang({ className }: Props): React.ReactElement {
  const { i18n } = useTranslation(I18nNs.Common);
  const [hover, setHover] = useState<boolean>(false);

  const lang = useLang();
  const userLang = useUserLang();
  const userId = useUserId();
  const setUser = useSetUser();

  useDebounce(
    async () => {
      if (!userId) return;
      if (userLang === lang) return;
      await updateMe({ lang });
      const user = await getMe();
      setUser(user);
    },
    2000,
    [userLang, lang, setUser, userId],
  );

  const { otherLang, actualLang } = useMemo(
    () => ({
      actualLang: i18n.language,
      otherLang: i18n.languages.find((l) => l !== i18n.language),
    }),
    [i18n.language, i18n.languages],
  );

  return (
    <Box
      className={className}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <Tooltip title={actualLang === 'fr-FR' ? 'English' : 'Français'}>
        <ButtonBase onClick={() => i18n.changeLanguage(otherLang)}>
          {hover && <img style={{ width: '40px' }} src={`/${otherLang}.svg`} alt={otherLang} />}
          {!hover && <img style={{ width: '40px' }} src={`/${actualLang}.svg`} alt={actualLang} />}
        </ButtonBase>
      </Tooltip>
    </Box>
  );
}
