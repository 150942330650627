import { ChildericColor } from '@/util/theme';
import { Paper } from '@mui/material';
import Color from 'color';
import styled from 'styled-components';

export const StyledOrderList = styled(Paper)`
  tbody tr[data-is-even='true'] {
    background-color: ${Color(ChildericColor.Secondary).alpha(0.1).toString()};
  }

  tbody tr:hover {
    background-color: ${Color(ChildericColor.Secondary).alpha(0.3).toString()};
  }
`;
