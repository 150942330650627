import { ChildericColor } from '@/util/theme';
import React from 'react';
import { ThreeDots } from 'react-loader-spinner';

type Props = {
  size?: number;
  className?: string;
  color?: string;
};

export default function Loader({
  size = 30,
  className,
  color = ChildericColor.Optionnal,
}: Props): React.ReactElement {
  return (
    <div className={className} style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
      <ThreeDots color={color} height={size} width={size * 2} />
    </div>
  );
}

export { Loader };
