import { OrderStatus, orderStatusValues } from '@/enum/index.ts';
import * as yup from 'yup';
import { PageInput, pageValidator } from '../../common/index.ts';

export type ListChildericOrdersQueryInput = PageInput & {
  s?: string;
  status?: OrderStatus[];
  linkedResellerCustomerId?: string | null;
  onlyInvoiced?: boolean;
};

export const listChildericOrdersQueryValidator = yup
  .object({
    s: yup.string().optional(),
    status: yup.array().of(yup.string().oneOf(orderStatusValues).required()).optional(),
    linkedResellerCustomerId: yup.string().uuid().optional(),
    onlyInvoiced: yup.boolean().optional(),
  })
  .concat(pageValidator);
