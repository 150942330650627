import { I18nNs } from '@/util/i18n';
import { Typography } from '@mui/material';
import React from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { ThreeDots } from 'react-loader-spinner';
import { Image, LoaderBackground, LoaderContainer, LoaderContent } from './SplashScreen.style';

type Props = {
  opacity?: number;
  message?: string | null;
};

export default function SplashScreen({ opacity, message }: Props): React.ReactElement {
  const { t } = useTranslation([I18nNs.Component, I18nNs.Common]);

  return createPortal(
    <LoaderContainer>
      <LoaderBackground $opacity={opacity || 1} />
      <LoaderContent>
        <div style={{ textAlign: 'center' }}>
          <Image src="/logo100.png" alt={t('logo', { ns: I18nNs.Common })} />
          <ThreeDots color="#F15722" height={50} width={100} />
          <Typography variant="body1" color="#F15722">
            {message || t('splashScreen.loading')}
          </Typography>
        </div>
      </LoaderContent>
    </LoaderContainer>,
    document.body,
  );
}

export { SplashScreen };
