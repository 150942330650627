import { mutateHistory } from '@/api/history/list-histories/list-histories.controller.ts';
import { config } from '@/util/config';
import React, { useCallback, useEffect } from 'react';
import { io } from 'socket.io-client';
import { HistorySocketContext } from './HistorySocketContext.ts';

type Props = {
  children: React.ReactNode;
};

const wsUrl = new URL('history', config.URL_API);
wsUrl.protocol = 'wss';

const socket = io(wsUrl.href);

export function HistorySocketProvider({ children }: Props): React.ReactElement {
  const mutate = useCallback(() => {
    mutateHistory();
  }, []);

  useEffect(() => {
    socket.on('mutate', mutate);
    return () => {
      socket.off('mutate', mutate);
    };
  }, [mutate]);

  return <HistorySocketContext.Provider value={null}>{children}</HistorySocketContext.Provider>;
}
