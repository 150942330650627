import { useHeader } from '@/context';
import { MenuLevel1 } from '@/enum';
import { ReactElement, useMemo } from 'react';
import { StyledNoLevel2 } from './Level2.style';
import {
  Level2Admin,
  Level2Compta,
  Level2Order,
  Level2Price,
  Level2Product,
  Level2Purchase,
  Level2Reseller,
  Level2Voucher,
} from './component';

export function Level2(): ReactElement {
  const { level1Path } = useHeader();

  const menuIndex = useMemo(
    () =>
      new Map<MenuLevel1 | null, ReactElement>([
        [MenuLevel1.Admin, <Level2Admin />],
        [MenuLevel1.Compta, <Level2Compta />],
        [MenuLevel1.Order, <Level2Order />],
        [MenuLevel1.Price, <Level2Price />],
        [MenuLevel1.Product, <Level2Product />],
        [MenuLevel1.Purchase, <Level2Purchase />],
        [MenuLevel1.Reseller, <Level2Reseller />],
        [MenuLevel1.Voucher, <Level2Voucher />],
        [null, <StyledNoLevel2 />],
      ]),
    [],
  );

  const menu2 = useMemo(() => menuIndex.get(level1Path || null) || null, [menuIndex, level1Path]);

  if (menu2) return menu2;

  return <StyledNoLevel2 />;
}
