import { I18n } from '@/enum';
import { formatDistanceToNow as dfnsFormatDistanceToNow, format } from 'date-fns';
import { enUS, fr } from 'date-fns/locale';

export { toDate } from './transform/to-date/to-date';

export function getDateWeek(date: string | Date | null, lang: I18n = I18n.FR): string {
  if (!date) return '';
  return format(new Date(date), 'w', {
    locale: lang === I18n.FR ? fr : enUS,
  }).toLowerCase();
}

export function getMonthString(date: Date, lang: I18n = I18n.FR): string {
  return format(new Date(date), 'MMMM', {
    locale: lang === I18n.FR ? fr : enUS,
  }).toLowerCase();
}

export function getSmallDateString(date: string | Date | null, lang: I18n = I18n.FR): string {
  if (!date) return '';
  return format(new Date(date), 'dd/MM/yyyy', {
    locale: lang === I18n.FR ? fr : enUS,
  }).toLowerCase();
}

export function getDateString(date: string | Date | null, lang: I18n = I18n.FR): string {
  if (!date) return '';
  return format(new Date(date), 'eeee d MMMM yyyy', {
    locale: lang === I18n.FR ? fr : enUS,
  }).toLowerCase();
}

export function getDateTimeString(date: string | Date | null, lang: I18n = I18n.FR): string {
  if (!date) return '';

  if (lang === I18n.FR) {
    return format(new Date(date), "eeee d MMMM yyyy 'à' H'h'mm", {
      locale: fr,
    }).toLowerCase();
  }

  return format(new Date(date), "eeee, MMMM d, yyyy, 'at' h:mm aaaa", {
    locale: enUS,
  }).toLowerCase();
}

export function formatDistanceToNow(date: string | Date | null, lang: I18n = I18n.FR): string {
  if (!date) return '';
  return dfnsFormatDistanceToNow(new Date(date), {
    addSuffix: true,
    locale: lang === I18n.FR ? fr : enUS,
  });
}
