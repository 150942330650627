import { useHeader } from '@/context';
import SearchIcon from '@mui/icons-material/Search';
import clsx from 'clsx';
import { StyledSearchLink } from './Search.style';

export function Search() {
  const { isActive } = useHeader();
  return (
    <li>
      <StyledSearchLink to="/search" className={clsx(isActive('/search') && 'is-active')}>
        <span className="round">
          <SearchIcon sx={{ width: 20, height: 20 }} />
        </span>
      </StyledSearchLink>
    </li>
  );
}
