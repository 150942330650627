import BackupTestInfo from '@/component/BackupTestInfo';
import BreadcrumbItem from '@/component/BreadcrumbItem';
import Container from '@/component/Container';
import DeliveryOrderReadyMessage from '@/component/DeliveryOrderReadyMessage';
import PurchaseOrderAlertsMessage from '@/component/PurchaseOrderAlertsMessage';
import Title from '@/component/Title';
import { UserRole } from '@/enum';
import { useUserHasEveryRoles, useUserHasSomeRole } from '@/hook/user';
import { I18nNs } from '@/util/i18n';
import { Grid, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { OrderList, StatSaleRollingYearByMonth } from './component';
import { StatNewCustomerByMonth } from './component/StatNewCustomerByMonth/StatNewCustomerByMonth';
import { StatSaleByCountry } from './component/StatSaleByCountry/StatSaleByCountry';
import { useKey } from './hook/useKey';

export default function DashboardScene() {
  const { t } = useTranslation([I18nNs.Dashboard, I18nNs.Stat]);
  const hasSomeRole = useUserHasSomeRole();
  const hasEveryRoles = useUserHasEveryRoles();
  const key = useKey();

  const title1 = t('title');
  const title2 = t('title.dashboard');

  return (
    <BreadcrumbItem label={title1} path="/dashboard" position={1}>
      <BreadcrumbItem label={title2} path="/dashboard" position={2}>
        <Title value={title2} />
        <Container key={key}>
          <BackupTestInfo />
          {hasSomeRole(UserRole.AdminReseller, UserRole.God) && (
            <>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <StatSaleRollingYearByMonth id="spark-a" />
                </Grid>
                <Grid item xs={4}>
                  <StatNewCustomerByMonth id="spark-b" />
                </Grid>
                <Grid item xs={4}>
                  <StatSaleByCountry id="spark-c" />
                </Grid>
              </Grid>
            </>
          )}

          {hasEveryRoles(UserRole.Admin, UserRole.Delivery) && <DeliveryOrderReadyMessage />}
          {hasEveryRoles(UserRole.Purchase) && <PurchaseOrderAlertsMessage />}

          {hasSomeRole(UserRole.Admin, UserRole.AdminReseller, UserRole.Commercial) && (
            <Stack direction="row" sx={{ mt: 2 }}>
              <OrderList />
            </Stack>
          )}

          {/* <Stack sx={{ mt: 4 }} direction="row" justifyContent="center">
            <InstallBtn />
          </Stack> */}
        </Container>
      </BreadcrumbItem>
    </BreadcrumbItem>
  );
}
