import { useHeader } from '@/context';
import { MenuLevel1, MenuLevel2, UserRole } from '@/enum';
import { useUserHasRole, useUserHasSomeRole } from '@/hook/user';
import { I18nNs } from '@/util/i18n';
import CategoryIcon from '@mui/icons-material/Category';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Filter1Icon from '@mui/icons-material/Filter1';
import PercentIcon from '@mui/icons-material/Percent';
import PrintIcon from '@mui/icons-material/Print';
import { useTranslation } from 'react-i18next';
import { Level2Item } from '../../Level2Item/Level2Item';
import { StyledLevel2 } from '../Level2.style';

export function Level2Compta() {
  const hasRole = useUserHasRole();
  const hasSomeRole = useUserHasSomeRole();
  const { t } = useTranslation(I18nNs.Menu);
  const { level1Path } = useHeader();

  if (level1Path !== MenuLevel1.Compta) return null;
  if (!hasRole(UserRole.Compta)) return null;

  return (
    <StyledLevel2 component="ul">
      <Level2Item
        label={t('menu.compta.export')}
        to={MenuLevel2.ComptaExport}
        icon={<FileDownloadIcon />}
      />
      <Level2Item label={t('menu.compta.vat')} to={MenuLevel2.ComptaVat} icon={<PercentIcon />} />
      <Level2Item
        label={t('menu.compta.family')}
        to={MenuLevel2.ComptaFamily}
        icon={<CategoryIcon />}
      />
      <Level2Item
        label={t('menu.compta.account-number')}
        to={MenuLevel2.ComptaAccountNumber}
        icon={<Filter1Icon />}
      />
      {!hasSomeRole(UserRole.Admin, UserRole.AdminReseller) && (
        <Level2Item
          label={t('menu.compta.order-frame')}
          to={MenuLevel2.ComptaOrderFrame}
          icon={<PrintIcon />}
        />
      )}
    </StyledLevel2>
  );
}
