import { axios } from '@/util/axios';
import { toDate } from '@/util/date';
import { getUrl } from '@/util/get-url';
import { handleError } from '@/util/handle-error';
import swr, { SWRResponse } from 'swr';
import { ListPaymentsQueryInput, listPaymentsQueryValidator } from './list-payments.input.ts';
import { ListPaymentsOutput } from './list-payments.output.ts';

const path = '/payment';

export const listPayments = async (query: ListPaymentsQueryInput): Promise<ListPaymentsOutput> => {
  try {
    await listPaymentsQueryValidator.validate(query);
    const url = getUrl(path, query);
    return await axios.get<ListPaymentsOutput>(url).then((res) => ({
      ...res.data,
      docs: res.data.docs.map((d) => ({
        ...d,
        toBePayAt: toDate(d.toBePayAt),
        payAt: toDate(d.payAt),
      })),
    }));
  } catch (err) {
    return handleError(path, err, { query });
  }
};

export const useListPayments = (query: ListPaymentsQueryInput): SWRResponse<ListPaymentsOutput> => {
  const url = getUrl(path, query);
  return swr<ListPaymentsOutput>(url, () => listPayments(query));
};
