import { ReactNode, useEffect, useState } from 'react';
import { StyledBadge } from './Badge.style';

type Props = {
  value: number;
  children: ReactNode;
};
export function Badge({ value, children }: Props) {
  const [unreadNb, setUnreadNb] = useState(value);

  useEffect(() => {
    const id = setTimeout(() => {
      setUnreadNb(value);
    }, 1100);
    return () => {
      clearTimeout(id);
    };
  }, [value]);

  if (typeof value !== 'number') return children;
  if (value === 0) return children;

  return (
    <StyledBadge $nb={value} $animate={value > 0 && unreadNb < value}>
      {children}
      <span className="badge">
        <span className="badge-text">{value < 100 ? value : '99+'}</span>
      </span>
    </StyledBadge>
  );
}
