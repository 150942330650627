import { useListPurchaseAlerts } from '@/api/purchase/list-purchase-alerts';
import { UserRole } from '@/enum';
import { useUserHasRole } from '@/hook/user';
import { Alert, Box, Button } from '@mui/material';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';

function PurchaseOrderAlertsMessageSecureAccess() {
  const { data } = useListPurchaseAlerts();

  const nb = useMemo(() => {
    if (!Array.isArray(data)) return 0;
    if (data.length === 0) return 0;

    return data.reduce((acc, supplier) => {
      return acc + supplier.purchaseProducts.length;
    }, 0);
  }, [data]);

  const message = useMemo(() => {
    if (!Array.isArray(data)) return null;
    if (data.length === 0) return null;
    if (data.length === 1) {
      if (nb === 1) {
        return `Il y a un article à commander chez le fournisseur ${data[0].name}.`;
      }
      return `Il y a ${nb} articles à commander chez le fournisseur ${data[0].name}.`;
    }

    return `Il y a ${nb} articles à commander auprès de plusieurs fournisseurs.`;
  }, [data, nb]);

  if (!message) return null;

  return (
    <Box sx={{ mt: 2, mb: 2 }}>
      <Alert
        severity="warning"
        action={
          <Button
            component={Link}
            to="/purchase/alerts"
            sx={{ mb: 1 }}
            size="small"
            variant="contained"
            className="show-btn"
          >
            Afficher les alertes
          </Button>
        }
      >
        {message}
      </Alert>
    </Box>
  );
}

export function PurchaseOrderAlertsMessage() {
  const hasRole = useUserHasRole();

  if (!hasRole(UserRole.Purchase)) return null;

  return <PurchaseOrderAlertsMessageSecureAccess />;
}
