import React, { useMemo } from 'react';
import { useController, Control, Path, FieldValues } from 'react-hook-form';
import FormHelperText from '@mui/material/FormHelperText';
import get from 'lodash/get';

type Props<T extends FieldValues> = {
  control: Control<T>;
  name: Path<T>;
};

export function ErrorMessage<T extends FieldValues>({
  control,
  name,
}: Props<T>): React.ReactElement | null {
  const {
    fieldState: { error },
  } = useController<T>({
    name,
    control,
  });

  const errorMessage = useMemo(() => {
    if (!error) return null;

    const nameMessage = get(error, `message`);
    if (typeof nameMessage === 'string') return nameMessage;

    if (!Array.isArray(error)) return null;

    const inArrayError = error.find((el: any) => el?.message);
    if (!inArrayError) return null;

    return inArrayError.message;
  }, [error]);

  if (!errorMessage) {
    return null;
  }

  return (
    <FormHelperText error sx={{ mb: 1 }} key={errorMessage}>
      {errorMessage}
    </FormHelperText>
  );
}
