import { useHeader } from '@/context';
import { MenuLevel1, MenuLevel2, UserRole } from '@/enum';
import { useUserHasSomeRole } from '@/hook/user';
import { I18nNs } from '@/util/i18n';
import { useTranslation } from 'react-i18next';
import { Level2Item } from '../../Level2Item/Level2Item';
import { StyledLevel2 } from '../Level2.style';

export function Level2Voucher() {
  const hasSomeRole = useUserHasSomeRole();
  const { t } = useTranslation(I18nNs.Menu);
  const { level1Path } = useHeader();

  if (level1Path !== MenuLevel1.Voucher) return null;
  if (!hasSomeRole(UserRole.Voucher, UserRole.VoucherAdmin)) return null;

  return (
    <StyledLevel2 component="ul">
      <Level2Item label={t('menu.voucher.prod')} to={MenuLevel2.VoucherProd} />
      <Level2Item label={t('menu.voucher.divers')} to={MenuLevel2.VoucherDivers} />
    </StyledLevel2>
  );
}
