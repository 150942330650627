import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Box, Link, Stack } from '@mui/material';
import { ReactElement } from 'react';
import { StyledLinkTargetBlank } from './LinkTargetBlank.style';

type Props = {
  to: string;
  label: string | number;
};

export function LinkTargetBlank({ to, label }: Props): ReactElement {
  return (
    <Link
      component={StyledLinkTargetBlank}
      to={to}
      target="_blank"
      rel="noopener"
      alignItems="center"
      title={`${label}`}
    >
      <Stack direction="row" alignItems="center" justifyContent="flex-start">
        <Box className="ellipsis">{label}</Box>
        <OpenInNewIcon sx={{ fontSize: '15px', ml: '3px', mt: '2px' }} />
      </Stack>
    </Link>
  );
}
