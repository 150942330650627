import Lang from '@/component/Lang';
import Loader from '@/component/Loader';
import { useRedirectAfterLogin } from '@/hook/useRedirectAfterLogin';
import { useUserIsLogged } from '@/hook/user';
import { LockOutlined } from '@mui/icons-material';
import { Avatar } from '@mui/material';
import { Suspense, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import {
  StyledOfflineLayout,
  StyledOfflineLayoutContent,
  StyledOfflineLayoutImg,
  StyledOfflineLayoutLang,
} from './OfflineLayout.style';

export function OfflineLayout() {
  const isLogged = useUserIsLogged();
  const redirect = useRedirectAfterLogin();

  useEffect(() => {
    if (isLogged) redirect();
  }, [isLogged, redirect]);

  return (
    <StyledOfflineLayout>
      <StyledOfflineLayoutImg />
      <StyledOfflineLayoutLang>
        <Lang />
      </StyledOfflineLayoutLang>
      <StyledOfflineLayoutContent>
        <div className="avatar">
          <Avatar sx={{ mt: 2, bgcolor: 'secondary.main' }}>
            <LockOutlined />
          </Avatar>
        </div>
        <Suspense fallback={<Loader />}>
          <Outlet />
        </Suspense>
      </StyledOfflineLayoutContent>
    </StyledOfflineLayout>
  );
}
