import { axios } from '@/util/axios';
import { toDate } from '@/util/date';
import { getUrl } from '@/util/get-url';
import { handleError } from '@/util/handle-error';
import swr, { SWRResponse } from 'swr';
import { GetHealthcheckOutput } from './get-healthcheck.output.ts';

const path = '/healthcheck';

export const getHealthcheck = async (): Promise<GetHealthcheckOutput> => {
  try {
    const url = getUrl(path);
    return await axios.get<GetHealthcheckOutput>(url).then((res) => ({
      lastHealthCheck: toDate(res?.data?.lastHealthCheck),
    }));
  } catch (err) {
    return handleError(path, err, {});
  }
};

export const useGetHealthcheck = (enabled: boolean = true): SWRResponse<GetHealthcheckOutput> => {
  const url = enabled ? getUrl(path) : null;
  return swr<GetHealthcheckOutput>(url, () => getHealthcheck());
};
