import { ChatType } from '@/enum';
import { userStore } from '@/store/user';
import { config } from '@/util/config';
import { Manager, Socket } from 'socket.io-client';
import { ChatClientToServer, ChatServerToClient } from './chat.socket.dto';

const wsUrl = new URL('/', config.URL_API);
wsUrl.protocol = 'wss';

const chatSocketManager = new Manager(wsUrl.href, {
  autoConnect: true,
  withCredentials: true,
});

export const chatSocket: Socket<ChatServerToClient, ChatClientToServer> = chatSocketManager.socket(
  '/chat',
  {
    auth: (cb) => {
      cb({
        token: userStore.getState().token,
      });
    },
  },
);

chatSocket.on('connect', () => {
  const token = userStore.getState().token;
  if (typeof token === 'string') {
    chatSocket.emit('initialize');
  }
});

chatSocket.io.on('error', (err) => {
  console.error('err', err);
});

userStore.subscribe((state, prevState) => {
  const hasToken = typeof state.token === 'string';

  if (!hasToken && chatSocket.connected) {
    chatSocket.disconnect();
    return;
  }
  if (hasToken && !chatSocket.connected) {
    chatSocket.connect();
    return;
  }

  if (state.token === prevState.token) return;

  if (hasToken) {
    chatSocket.disconnect();
    chatSocket.connect();
  } else {
    chatSocket.disconnect();
  }
});

export const chatJoin = (type: ChatType, docId: string) => {
  chatSocket.emit('joinRoom', { type, docId });
};

export const chatLeave = (type: ChatType, docId: string) => {
  chatSocket.emit('leaveRoom', { type, docId });
};
