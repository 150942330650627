import { PageOutput } from '@/api/common/output/page.output';
import { LRUCache } from 'lru-cache';
import swr, { SWRResponse } from 'swr';

const cache = new LRUCache<string, Promise<any[]>>({
  ttl: 1000,
  max: 100,
});

type QueryPage = {
  page: number;
  limit: number;
};

type GetFunction<T> = (query: QueryPage) => Promise<PageOutput<T>>;

async function run<T>(fn: GetFunction<T>, page = 1, all: T[] = []): Promise<T[]> {
  const { hasNextPage, docs } = await fn({ page, limit: 100 });

  if (!hasNextPage) {
    return [...all, ...docs];
  }

  return run(fn, page + 1, [...all, ...docs]);
}

/**
 * @param fn Set query function (ex: (q) => api.order.getPage({customerId: 'xxx-xxx', ...q})`)
 * @param cacheKey Set uniq cache key (ex: `api.order.getPage_{customerId: "xxxxx-xxx"}`)
 */
export async function getPageToAll<T>(fn: GetFunction<T>, cacheKey?: string | null): Promise<T[]> {
  const hasCache = typeof cacheKey === 'string' && cacheKey.length > 0;
  if (hasCache && cache.has(cacheKey)) {
    const res = cache.get(cacheKey);
    if (res !== undefined) return res;
  }

  const resP = run(fn, 1, []);
  if (hasCache) {
    cache.set(cacheKey, resP);
  }

  return resP;
}

/**
 * @param cacheKey Set uniq cache key (ex: `api.order.getPage_{customerId: "xxxxx-xxx"}`)
 * @param fn Set query function (ex: (q) => api.order.getPage({customerId: 'xxx-xxx', ...q})`)
 */
export const usePageToAll = <T>(cacheKey: string | null, fn: GetFunction<T>): SWRResponse<T[]> =>
  swr(cacheKey, () => getPageToAll(fn, cacheKey));
