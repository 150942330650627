import styled from 'styled-components';

export const StyledMenuLevel1 = styled.div`
  display: flex;
  height: 75px;
  align-items: streich;
  justify-content: space-around;
  width: 100%;

  @media (max-width: 1200px) {
    justify-content: space-evenly;
  }
`;

export const StyledMenu = styled.div`
  width: 100%;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 500;
`;
