import { GetMeOutput } from '@/api/auth/get-me';
import { FixedId, I18n, UserRole } from '@/enum';
import { userLangToLng } from '@/util/i18n';
import * as Sentry from '@sentry/react';
import { changeLanguage } from 'i18next';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { sync } from '../util/sync';
import { UserStore } from './user.store.dto';

export const userStore = create<UserStore>()(
  persist(
    (set, get) => ({
      isLogged: false,
      token: null,
      user: null,
      isAdmin: false,
      isAdminReseller: false,
      isGod: false,
      isLabymind: false,
      isImpersonate: false,
      isChilderic: false,
      reseller: null,

      setUser: (user: GetMeOutput | null) => {
        set({ user });
      },
      hasEveryRoles: (...roles: UserRole[]): boolean => {
        const user = get().user;
        if (!Array.isArray(user?.roles)) return false;
        return roles.every((role) => user?.roles.includes(role)) || false;
      },

      hasRole: (role: UserRole) => {
        const user = get().user;
        if (!Array.isArray(user?.roles)) return false;
        return user?.roles.includes(role) || false;
      },

      hasSomeRole: (...roles: UserRole[]) => {
        const user = get().user;
        if (!Array.isArray(user?.roles)) return false;
        return roles.some((role) => user?.roles.includes(role)) || false;
      },
      logout: () => {
        set({
          token: null,
          user: null,
          isAdmin: false,
          isAdminReseller: false,
          isGod: false,
          isImpersonate: false,
        });
      },
    }),
    {
      name: 'user',
    },
  ),
);
sync(userStore);

const hasRole = (user: UserStore['user'], role: UserRole): boolean => {
  if (!Array.isArray(user?.roles)) return false;
  return user?.roles.includes(role) || false;
};

userStore.subscribe(async (state, oldState) => {
  if (state.user) {
    Sentry.setUser({ id: state.user.id, email: state.user.email });
  } else {
    Sentry.setUser(null);
  }

  const isLogged = typeof state?.user?.id === 'string';
  if (state?.isLogged !== isLogged) {
    userStore?.setState({ isLogged });
  }

  const isChilderic = state?.user?.resellerId === FixedId.Reseller_Childeric;
  if (state?.isChilderic !== isChilderic) {
    userStore.setState({ isChilderic });
  }

  const isAdmin = hasRole(state.user, UserRole.Admin);
  if (state.isAdmin !== isAdmin) {
    userStore.setState({ isAdmin });
  }
  const isAdminReseller = hasRole(state.user, UserRole.AdminReseller);
  if (state.isAdminReseller !== isAdminReseller) {
    userStore.setState({ isAdminReseller });
  }
  const isGod = hasRole(state.user, UserRole.God);
  if (state.isGod !== isGod) {
    userStore.setState({ isGod });
  }
  const isLabymind = state.user?.login === 'jphilippon';
  if (state.isLabymind !== isLabymind) {
    userStore.setState({ isLabymind });
  }

  if (state.user && state.user?.lang !== oldState?.user?.lang) {
    changeLanguage(userLangToLng(state.user.lang || I18n.FR));
  }

  const isImpersonate = typeof state.token === 'string' && state.token.startsWith('imp-');
  if (state.isImpersonate !== isImpersonate) {
    userStore.setState({ isImpersonate });
  }
});
