import SplashScreen from '@/component/SplashScreen/SplashScreen.tsx';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { PopupLoaderContext } from './PopupLoaderContext.ts';

type Props = {
  children: React.ReactNode;
};

const BlurDiv = styled.div<{ $open: boolean }>`
  filter: blur(${({ $open }) => ($open ? '2px' : '0')});
  transition: filter 1s ease-in-out;
`;

export function PopupLoaderProvider({ children }: Props): React.ReactElement {
  const [message, setMessage] = useState<string | null>(null);
  const [open, setOpen] = useState<boolean>(false);

  const value = useCallback((o: boolean, message?: string | null) => {
    setOpen(o);
    setMessage(message || null);
  }, []);

  return (
    <PopupLoaderContext.Provider value={value}>
      <BlurDiv $open={open}>{children}</BlurDiv>
      {open ? <SplashScreen opacity={0.5} message={message} /> : null}
    </PopupLoaderContext.Provider>
  );
}
