import { useHeader } from '@/context';
import { UserRole } from '@/enum';
import { useUserHasSomeRole } from '@/hook/user';
import clsx from 'clsx';
import { ReactElement } from 'react';
import { StyledLevel2Item, StyledLevel2ItemLink } from './Level2Item.style';

type Props = {
  to: string;
  label: string;
  icon?: ReactElement;
  roles?: UserRole[];
};

export function Level2Item({ to, label, icon, roles }: Props) {
  const { isActive, color } = useHeader();
  const hasSomeRole = useUserHasSomeRole();

  if (Array.isArray(roles) && roles.length > 0 && !hasSomeRole(...roles)) {
    return null;
  }

  return (
    <StyledLevel2Item $menuColor={color} className={clsx(isActive(to) && 'is-active')}>
      <StyledLevel2ItemLink to={to}>
        {icon}
        {label}
      </StyledLevel2ItemLink>
    </StyledLevel2Item>
  );
}
