import { axios } from '@/util/axios';
import { toDate } from '@/util/date';
import { getUrl } from '@/util/get-url';
import { handleError } from '@/util/handle-error';
import swr, { SWRResponse } from 'swr';
import { listChatRemembersQueryValidator } from './list-chat-remembers.input.ts';
import { ListChatRemembersOutput } from './list-chat-remembers.output.ts';

const path = '/chat-remember';

export const listChatRemembers = async (page?: number): Promise<ListChatRemembersOutput> => {
  try {
    await listChatRemembersQueryValidator.validate(page);
    const url = getUrl(path, { page });
    return await axios.get<ListChatRemembersOutput>(url).then((res) => ({
      ...res.data,
      docs: res.data.docs.map((d) => ({
        ...d,
        rememberAt: toDate(d.rememberAt),
        createdAt: toDate(d.rememberAt),
      })),
    }));
  } catch (err) {
    return handleError(path, err, { page });
  }
};

export const useListChatRemembers = (page?: number): SWRResponse<ListChatRemembersOutput> => {
  const url = getUrl(path, { page });
  return swr<ListChatRemembersOutput>(url, () => listChatRemembers(page));
};
