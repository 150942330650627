import Loader from '@/component/Loader';
import { useRedirectToLogin } from '@/hook';
import { useUserIsLogged } from '@/hook/user';
import { Suspense, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { StyledConnectedLayout, StyledConnectedLayoutGlobal } from './ConnectedLayout.style';
import { Breadcrumb, Menu, Title } from './component';

export function ConnectedLayout() {
  const isLogged = useUserIsLogged();

  const redirectToLogin = useRedirectToLogin();

  useEffect(() => {
    if (!isLogged) redirectToLogin();
  }, [isLogged, redirectToLogin]);

  if (!isLogged) return null;

  return (
    <StyledConnectedLayout>
      <StyledConnectedLayoutGlobal />
      <Menu />
      <Breadcrumb />
      <Title />
      <div className="connected-outlet">
        <Suspense fallback={<Loader />}>
          <Outlet />
        </Suspense>
      </div>
    </StyledConnectedLayout>
  );
}
