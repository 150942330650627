import Suspense from '@/component/Suspense';
import { I18nNs } from '@/util/i18n';
import lazy from '@/util/lazy';
import React, { ReactElement, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfirmContext, ConfirmContextOptions, ConfirmContextType } from './ConfirmContext';

const Confirm = lazy(() => import('@/component/Confirm/Confirm'));

type Props = {
  children: React.ReactNode;
};

export function ConfirmProvider({ children }: Props): React.ReactElement {
  const [resolve, setResolve] = useState<null | ((res: boolean) => any)>();
  const [message, setMessage] = useState<string | ReactElement | null>(null);
  const { t } = useTranslation(I18nNs.Common);
  const [ko, setKo] = useState<string | null | false>(null);
  const [ok, setOk] = useState<string | null>(null);

  const confirm = useCallback<ConfirmContextType>(
    (m: string | ReactElement, options?: ConfirmContextOptions) =>
      new Promise((r) => {
        setMessage(m);
        if (options?.ok !== undefined) setOk(options.ok);
        if (options?.ko !== undefined) setKo(options.ko);
        setResolve(() => r);
      }),
    [],
  );

  return (
    <ConfirmContext.Provider value={confirm}>
      {message && (
        <Suspense>
          <Confirm
            content={message}
            ok={ok || t('yes', { ns: I18nNs.Common })}
            ko={ko === null ? t('no', { ns: I18nNs.Common }) : ko}
            onClick={(res) => {
              if (typeof resolve === 'function') {
                resolve(res);
              }
              setResolve(null);
              setMessage(null);
              setOk(null);
              setKo(null);
            }}
          />
        </Suspense>
      )}

      {children}
    </ConfirmContext.Provider>
  );
}
