import { mutateStatRoutes } from '@/api/util/mutate-route';
import { useUserId, useUserResellerId } from '@/hook/user';
import { useEffect } from 'react';

export const useKey = (): string => {
  const resellerId = useUserResellerId();
  const userId = useUserId();

  const minutes = `${new Date().getMinutes()}`;
  const hours = `${new Date().getHours()}`;

  const key = [resellerId, userId, minutes, hours].filter((v) => typeof v === 'string').join('-');

  useEffect(() => {
    if (!key) return;
    mutateStatRoutes();
  }, [key]);

  return key;
};
