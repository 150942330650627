import ErrorFallback from '@/component/ErrorFallback';
import {
  BreadcrumbProvider,
  ChatProvider,
  ConfirmProvider,
  HeaderProvider,
  HistorySocketProvider,
  RedirectProvider,
  SnackProvider,
  TitleProvider,
} from '@/context';
import '@/util/i18n';
import { theme } from '@/util/theme';
import { ThemeProvider } from '@emotion/react';
import Close from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { enGB as en, fr } from 'date-fns/locale';
import { SnackbarProvider, closeSnackbar } from 'notistack';
import { useCallback, useMemo } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { HelmetProvider } from 'react-helmet-async';
import 'react-indiana-drag-scroll/dist/style.css';
import { BrowserRouter } from 'react-router-dom';
import 'simplebar-react/dist/simplebar.min.css';
import { AppGlobalStyle } from './App.style';
import { LoadingProvider } from './context/LoadingContext';
import { PopupLoaderProvider } from './context/PopupLoaderContext/PopupLoaderProvider';
import { useLang } from './hook';
// test
import { Router } from './router';

export default function App() {
  const lang = useLang();

  const adapterLocal = useMemo(() => (lang === 'fr' ? fr : en), [lang]);

  const snackbarAction = useCallback(
    (snackbarId: string | number) => (
      <IconButton onClick={() => closeSnackbar(snackbarId)}>
        <Close />
      </IconButton>
    ),
    [],
  );

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <HelmetProvider>
        <SnackbarProvider
          preventDuplicate
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          classes={{ containerAnchorOriginTopRight: 'snackbarPosition' }}
          action={snackbarAction}
        >
          <LoadingProvider>
            <PopupLoaderProvider>
              <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={adapterLocal}>
                <SnackProvider>
                  <BrowserRouter>
                    <RedirectProvider>
                      <HeaderProvider>
                        <TitleProvider>
                          <BreadcrumbProvider>
                            <ConfirmProvider>
                              <ThemeProvider theme={theme}>
                                <HistorySocketProvider>
                                  <ChatProvider>
                                    <AppGlobalStyle />
                                    <Router />
                                  </ChatProvider>
                                </HistorySocketProvider>
                              </ThemeProvider>
                            </ConfirmProvider>
                          </BreadcrumbProvider>
                        </TitleProvider>
                      </HeaderProvider>
                    </RedirectProvider>
                  </BrowserRouter>
                </SnackProvider>
              </LocalizationProvider>
            </PopupLoaderProvider>
          </LoadingProvider>
        </SnackbarProvider>
      </HelmetProvider>
    </ErrorBoundary>
  );
}

export { App };
