import { I18nNs } from '@/util/i18n';
import Alert from '@mui/material/Alert';
import React from 'react';
import { Control, FieldValues, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type Props<T extends FieldValues> = {
  control: Control<T>;
};

export function InvalidFormMessage<T extends FieldValues>({
  control,
}: Props<T>): React.ReactElement | null {
  const { t } = useTranslation(I18nNs.Field);
  const { errors } = useFormState({
    control,
  });

  const isValid = Object.keys(errors).length === 0;

  if (isValid) return null;

  return <Alert severity="error">{t('form.invalid')}</Alert>;
}
