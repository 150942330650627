// Load Localforage before all //
import '@/util/lf';
// Load Localforage before all //

// polyfill
import 'core-js/actual/array/at';
import 'core-js/actual/promise/all-settled';
import 'core-js/actual/promise/with-resolvers';

import { config } from '@/util/config';
import * as Sentry from '@sentry/react';
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import App from './App';

Sentry.init({
  dsn: config.SENTRY_DSN,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  ignoreErrors: [
    /^Request failed with status code 401$/,
    /^Request failed with status code 403$/,
    /^Request failed with status code 404$/,
  ],

  integrations: [
    // See docs for support of different versions of variation of react router
    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],

  enabled: config.ENV !== 'development',
  environment: config.ENV,
  release: `app.childericsellier.com@${config.APP_VERSION}`,
  tracesSampleRate: 1.0,
});

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
