import { config } from '@/util/config';
import { useCallback, useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

type UseRedirectToLoginOutput = () => any;

export const useRedirectAfterLogin = (): UseRedirectToLoginOutput => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const url = useMemo(() => {
    const u = searchParams.get('redirect');
    if (!u) return '/dashboard';
    if (!config.URL_WWW) return '/dashboard';
    const to = new URL(u, config.URL_WWW);
    return [to.pathname, to.search, to.hash].join('');
  }, [searchParams]);

  return useCallback(() => {
    if (url) navigate(url);
  }, [navigate, url]);
};
