export enum UserRole {
  Admin = 'admin', // admin
  AdminReseller = 'admin-reseller', // admin (revendeur)
  Commercial = 'commercial',
  Compta = 'compta',
  Delivery = 'delivery',
  God = 'god', // Super admin
  Panel = 'panel',
  Payment = 'payment',
  Price = 'price',
  Purchase = 'purchase',
  RepairRead = 'repair-read',
  Sms = 'sms',
  Stock = 'stock',
  Tree = 'tree',
  Voucher = 'voucher',
  VoucherAdmin = 'voucher-admin',
}

export const userRoleValues = Object.values(UserRole);
