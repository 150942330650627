import { axios } from '@/util/axios';
import { getUrl } from '@/util/get-url';
import { handleError } from '@/util/handle-error';
import swr, { SWRResponse } from 'swr';
import { uuidValidator } from '../../common/index.ts';
import { ListAllProductTypesByResellerOutput } from './list-all-product-types-by-reseller.output.ts';

const path = '/reseller/:resellerId/product-type';

export const listAllProductTypesByReseller = async (
  resellerId: string,
): Promise<ListAllProductTypesByResellerOutput> => {
  try {
    await uuidValidator.validate(resellerId);
    const url = getUrl(path, { resellerId });
    return await axios.get<ListAllProductTypesByResellerOutput>(url).then((res) => res.data);
  } catch (err) {
    return handleError(path, err, { resellerId });
  }
};

export const useListAllProductTypesByReseller = (
  resellerId: string | null,
): SWRResponse<ListAllProductTypesByResellerOutput> => {
  const url = resellerId ? getUrl(path, { resellerId }) : null;
  return swr<ListAllProductTypesByResellerOutput>(url, () =>
    listAllProductTypesByReseller(resellerId as string),
  );
};
