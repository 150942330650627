import { chatSocket } from '@/socket/chat';
import { useCallback, useEffect, useState } from 'react';
import { useUserIsLogged } from './user';

export function useChatCountUserAssigned(): number {
  const [nb, setNb] = useState<number>(0);
  const isLogged = useUserIsLogged();

  const onCount = useCallback((dto: number) => {
    if (typeof dto !== 'number') return;
    setNb(dto);
  }, []);

  useEffect(() => {
    if (isLogged) return;
    setNb(0);
  }, [isLogged]);

  useEffect(() => {
    chatSocket.emit('getCountUserMessageAssigned', onCount);
    chatSocket.on('onCountUserAssignedChange', onCount);

    return () => {
      chatSocket.off('onCountUserAssignedChange', onCount);
    };
  }, [onCount]);

  return nb;
}
