import { useListOrdersReadyForDelivery } from '@/api/delivery/list-orders-ready-for-delivery';
import { LinkTargetBlank } from '@/component/LinkTargetBlank/LinkTargetBlank';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {
  Alert,
  Box,
  Button,
  DialogContent,
  IconButton,
  Table,
  TableBody,
  TableHead,
  TableRow,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import TableCell from '@mui/material/TableCell';
import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

type Props = {
  showIfEmpty?: boolean;
};

export function DeliveryOrderReadyMessage({ showIfEmpty = false }: Props) {
  const { data: ready } = useListOrdersReadyForDelivery();
  const [open, setOpen] = useState<boolean>(false);

  const message = useMemo(() => {
    if (!Array.isArray(ready)) return null;

    const nb = ready.length;

    if (nb === 0) return null;

    if (nb === 1) return `1 commande est prête pour l'expédition.`;
    return `${nb} commandes sont prêtes pour l'expédition.`;
  }, [ready]);

  if (showIfEmpty && !message) {
    return <Alert severity="info">Aucune commande n'est en attente d'expédition.</Alert>;
  }

  if (!message) return null;
  if (!Array.isArray(ready)) return null;

  return (
    <Box sx={{ mt: 2, mb: 2 }}>
      <Alert
        severity="info"
        action={
          ready.length === 1 ? (
            <Button
              component={Link}
              to={`/order/show/${ready[0].id}`}
              sx={{ mb: 1 }}
              size="small"
              variant="contained"
              className="show-btn"
            >
              Afficher la commande
            </Button>
          ) : (
            <Button sx={{ mb: 1 }} size="small" variant="contained" onClick={() => setOpen(true)}>
              Afficher la liste
            </Button>
          )
        }
      >
        {message}
      </Alert>

      <Dialog onClose={() => setOpen(false)} open={open}>
        <DialogTitle>Commandes prêtes</DialogTitle>

        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Commande</TableCell>
              <TableCell>Client</TableCell>
              <TableCell>Pour</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {ready.map((r) => {
              const to = `/order/show/${r.id}`;
              return (
                <TableRow>
                  <TableCell>
                    <LinkTargetBlank to={to} label={r.number} />
                  </TableCell>
                  <TableCell>{r.customerName}</TableCell>
                  <TableCell>{r.for}</TableCell>
                  <TableCell>
                    <IconButton component={Link} to={to} size="small" className="show-btn">
                      <ArrowForwardIosIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>

        <DialogContent></DialogContent>
      </Dialog>
    </Box>
  );
}
