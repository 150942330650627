import { axios } from '@/util/axios';
import { getUrl } from '@/util/get-url';
import { handleError } from '@/util/handle-error';
import { uuidValidator } from '../../common/index.ts';
import { ImpersonateOutput } from './impersonate.output.ts';

const path = '/auth/impersonate/:userId';

export const impersonate = async (userId: string): Promise<string> => {
  try {
    await uuidValidator.validate(userId);
    const url = getUrl(path, { userId });
    return await axios.post<ImpersonateOutput>(url).then((res) => res?.data?.xsrf);
  } catch (err) {
    return handleError(path, err, { userId });
  }
};
