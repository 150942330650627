import { axios } from '@/util/axios';
import { getUrl } from '@/util/get-url';
import { handleError } from '@/util/handle-error';
import swr, { SWRResponse } from 'swr';
import { ListPurchaseAlertsOutput } from './list-purchase-alerts.output.ts';

const path = '/purchase-product-helpers/alerts';

export const listPurchaseAlerts = async (): Promise<ListPurchaseAlertsOutput> => {
  try {
    const url = getUrl(path);
    return await axios.get<ListPurchaseAlertsOutput>(url).then((res) => res.data);
  } catch (err) {
    return handleError(path, err, {});
  }
};

export const useListPurchaseAlerts = (): SWRResponse<ListPurchaseAlertsOutput> => {
  const url = getUrl(path);
  return swr<ListPurchaseAlertsOutput>(url, () => listPurchaseAlerts());
};
