import { userManager } from '@/manager';
import { userStore } from '@/store/user';
import axios, { AxiosError, InternalAxiosRequestConfig } from 'axios';
import { throttle } from 'lodash';
import { errorEmitter } from '../event';
import { config as appConfig } from './config';
import { printPdf } from './printPDF';

export const isWeb = typeof navigator !== 'undefined';
export const isSafari = isWeb && /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
export const isFirefox = isWeb && /Firefox/i.test(navigator.userAgent);

const axiosCreateConfig = {
  baseURL: appConfig.URL_API,
  withCredentials: true,
  crossdomain: true,
};

const xsrfTokenInterceptor = async (config: InternalAxiosRequestConfig): Promise<any> => {
  const xsrfToken = userStore.getState().token;
  if (typeof xsrfToken !== 'string') return config;

  const url = new URL(config.url || '', config.baseURL).href;

  if (!url.includes(appConfig.URL_API)) {
    return {
      ...config,
      withCredentials: false,
      crossdomain: true,
    };
  }

  return {
    ...config,
    headers: {
      ...config.headers,
      'x-xsrf-token': xsrfToken,
    },
  };
};

const showError = throttle((status: number | null) => {
  if (status !== null) {
    errorEmitter.emit('snack', status);

    if (status === 401) {
      userManager.logout();
    }
  } else {
    // No Response
    errorEmitter.emit('snack', 444);
  }
}, 2000);

export const handleAxiosError = (error: AxiosError) => showError(error?.response?.status || null);

const instance = axios.create(axiosCreateConfig);
instance.interceptors.request.use(xsrfTokenInterceptor);
instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    handleAxiosError(error);
    return Promise.reject(error);
  },
);

export const silentAxios = axios.create(axiosCreateConfig);
silentAxios.interceptors.request.use(xsrfTokenInterceptor);

export default instance;
export { instance as axios };

const downloadBlob = (data: Blob, filename: string) => {
  const href = URL.createObjectURL(data);

  // create "a" HTML element with href to file & click
  const link = document.createElement('a');
  link.href = href;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();

  // clean up "a" element & remove ObjectURL
  document.body.removeChild(link);
  URL.revokeObjectURL(href);
};

export const axiosDownloadPost = async (
  url: string,
  filename: string,
  body?: object,
): Promise<void> =>
  instance
    .post(url, body, {
      responseType: 'blob',
    })
    .then((response) => downloadBlob(response.data, filename));

export const axiosDownload = async (url: string, filename: string, query?: object): Promise<void> =>
  instance
    .get(url, {
      responseType: 'blob',
      params: query,
    })
    .then((response) => downloadBlob(response.data, filename));

export const axiosBase64 = async (url: string, query?: object): Promise<string | null> => {
  const blob = await instance.get(url, { responseType: 'blob', params: query }).then((r) => r.data);
  const reader = new FileReader();
  await new Promise((resolve, reject) => {
    reader.onload = resolve;
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });

  return reader.result?.toString() || null;
};

export const axiosPrintPdf = async (
  url: string,
  query?: object,
  filename?: string,
): Promise<void> => {
  if (isSafari) {
    await axiosDownload(url, filename || 'file.pdf', query);
    return;
  }

  try {
    instance
      .get<ArrayBuffer>(url, { responseType: 'blob', params: query })
      .then((response) => printPdf(response.data));
  } catch (err) {
    console.error(err);
    axiosDownload(url, filename || 'file.pdf', query);
  }
};
