import { OrderStatus, orderStatusValues } from '@/enum/index.ts';
import * as yup from 'yup';
import { PageInput, pageValidator } from '../../common/index.ts';

export type ListOrdersQueryInput = PageInput & {
  s?: string;
  customerId?: string;
  originalOrderId?: string;
  status?: OrderStatus[];
  createdAtAfter?: Date;
  createdAtBefore?: Date;
  onlyInvoiced?: boolean;
};

export const listOrdersQueryValidator = yup
  .object({
    s: yup.string().optional(),
    customerId: yup.string().uuid().optional(),
    originalOrderId: yup.string().uuid().optional(),
    status: yup.array().of(yup.string().oneOf(orderStatusValues).required()).optional(),
    createdAtAfter: yup.date().optional(),
    createdAtBefore: yup.date().optional(),
    onlyInvoiced: yup.boolean().optional(),
  })
  .concat(pageValidator);
