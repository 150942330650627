import { axios } from '@/util/axios';
import { getUrl } from '@/util/get-url';
import { handleError } from '@/util/handle-error';
import swr, { SWRResponse } from 'swr';
import { GetMyResellerOutput } from './get-my-reseller.output.ts';

const path = '/auth/me/reseller';

export const getMyReseller = async (): Promise<GetMyResellerOutput> => {
  try {
    const url = getUrl(path);
    return await axios.get<GetMyResellerOutput>(url).then((res) => res.data);
  } catch (err) {
    return handleError(path, err, {});
  }
};

export const useGetMyReseller = (): SWRResponse<GetMyResellerOutput> => {
  const url = getUrl(path);
  return swr<GetMyResellerOutput>(url, getMyReseller);
};
