import { axios } from '@/util/axios';
import { toDate } from '@/util/date';
import { getUrl } from '@/util/get-url';
import { handleError } from '@/util/handle-error';
import swr, { SWRResponse } from 'swr';
import {
  ListChildericOrdersQueryInput,
  listChildericOrdersQueryValidator,
} from './list-childeric-orders.input.ts';
import { ListChildericOrdersOutput } from './list-childeric-orders.output.ts';

const path = '/childeric-order';

export const listChildericOrders = async (
  query: ListChildericOrdersQueryInput,
): Promise<ListChildericOrdersOutput> => {
  try {
    await listChildericOrdersQueryValidator.validate(query);
    const url = getUrl(path, query);
    return await axios.get<ListChildericOrdersOutput>(url).then((res) => ({
      ...res.data,
      docs: res.data.docs.map((d) => ({
        ...d,
        quoteAt: toDate(d.quoteAt),
        invoicedAt: toDate(d.invoicedAt),
        createdAt: toDate(d.createdAt),
        voucherEndOn: toDate(d.voucherEndOn),
        resellerCustomerName: d.resellerCustomerName || null,
      })),
    }));
  } catch (err) {
    return handleError(path, err, { query });
  }
};

export const useListChildericOrders = (
  query: ListChildericOrdersQueryInput,
): SWRResponse<ListChildericOrdersOutput> => {
  const url = getUrl(path, query);
  return swr<ListChildericOrdersOutput>(url, () => listChildericOrders(query));
};
