import { useHeader } from '@/context';
import { MenuLevel1 } from '@/enum';
import clsx from 'clsx';
import { useMemo } from 'react';
import { StyledLevel1Item, StyledLevel1ItemLink } from './Level1Item.style';

type Props = {
  to: MenuLevel1;
  label: string;
};

export function Level1Item({ to, label }: Props) {
  const { isActive, getColor } = useHeader();

  const color = useMemo(() => getColor(to), [getColor, to]);

  return (
    <StyledLevel1Item $menuColor={color} className={clsx(isActive(to) && 'is-active')}>
      <StyledLevel1ItemLink to={to}>
        <span>{label}</span>
      </StyledLevel1ItemLink>
    </StyledLevel1Item>
  );
}
