import Loader from '@/component/Loader';
import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { StyledPublicLayout } from './PublicLayout.style';

export function PublicLayout() {
  return (
    <StyledPublicLayout>
      <div className="connected-outlet">
        <Suspense fallback={<Loader />}>
          <Outlet />
        </Suspense>
      </div>
    </StyledPublicLayout>
  );
}
