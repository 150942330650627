import { useListPayments } from '@/api/payment/list-payments';
import { PaymentStatus } from '@/enum';
import { I18nNs } from '@/util/i18n';
import PaymentIcon from '@mui/icons-material/Payment';
import { Chip, Stack, Tab } from '@mui/material';
import Typography from '@mui/material/Typography';
import { ForwardedRef, forwardRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ETab } from '../enum';

type Props = {
  value: ETab;
  selected?: boolean;
};

export const TabPayment = forwardRef((props: Props, ref: ForwardedRef<any>) => {
  const { t } = useTranslation(I18nNs.Dashboard);
  const { data } = useListPayments({ status: [PaymentStatus.ShouldBePaid], limit: 1 });
  const totalDocs = useMemo(() => data?.totalDocs || 0, [data?.totalDocs]);

  return (
    <Tab
      ref={ref}
      {...props}
      icon={<PaymentIcon />}
      iconPosition="start"
      value={ETab.Payment}
      label={
        <Stack direction="row">
          <Typography variant="inherit">{t('tab.payment')}</Typography>
          {totalDocs > 0 && (
            <Chip
              size="small"
              sx={{ ml: 1, opacity: props.selected ? 1 : 0.7 }}
              label={totalDocs}
              color="primary"
            />
          )}
        </Stack>
      }
    />
  );
});
