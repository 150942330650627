import { createContext, ReactElement } from 'react';

export type ConfirmContextOptions = {
  ko?: false | string;
  ok?: string;
};

export type ConfirmContextType = (
  message: string | ReactElement,
  options?: ConfirmContextOptions,
) => Promise<boolean>;

export const ConfirmContext = createContext<ConfirmContextType>(async () => false);
