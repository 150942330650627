import { useBreadcrumb } from '@/context/BreadcrumbContext/useBreadcrumb';
import { ReactNode, useEffect } from 'react';

type Props = {
  label: string;
  path: string;
  position: number;
  children?: ReactNode;
};

export function BreadcrumbItem({ label, path, children, position }: Props) {
  const { addItem, removeItem } = useBreadcrumb();

  useEffect(() => {
    addItem({ label, path, position });

    return () => {
      removeItem({ label, path, position });
    };
  }, [label, path, addItem, removeItem, position]);

  return children;
}
