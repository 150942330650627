import { Link } from 'react-router-dom';
import { StyledLogo } from './Logo.style';

export function Logo() {
  return (
    <StyledLogo>
      <Link to="/dashboard">
        <img src="/logo-long.png" alt="Childéric logo" />
      </Link>
    </StyledLogo>
  );
}
