import { useListAllProductTypes } from '@/api/product/list-all-product-types';
import { useHeader } from '@/context';
import { MenuLevel1, MenuLevel2, UserRole } from '@/enum';
import { useLang, usePersistentValue } from '@/hook';
import { useUserHasRole } from '@/hook/user';
import { I18nNs } from '@/util/i18n';
import CategoryIcon from '@mui/icons-material/Category';
import DiscountIcon from '@mui/icons-material/Discount';
import DomainIcon from '@mui/icons-material/Domain';
import { useTranslation } from 'react-i18next';
import { Level2Item } from '../../Level2Item/Level2Item';
import { StyledLevel2 } from '../Level2.style';

export function Level2Product() {
  const hasRole = useUserHasRole();
  const { t } = useTranslation(I18nNs.Menu);
  const { level1Path } = useHeader();
  const lang = useLang();
  const { data } = useListAllProductTypes();

  const productTypes = usePersistentValue(data, [], (v) => Array.isArray(v));

  if (level1Path !== MenuLevel1.Product) return null;
  if (!hasRole(UserRole.God)) return null;

  return (
    <StyledLevel2 component="ul">
      <Level2Item
        label={t('menu.product.brand')}
        to={MenuLevel2.ProductBrand}
        icon={<DomainIcon />}
      />
      <Level2Item
        label={t('menu.product.type')}
        to={MenuLevel2.ProductType}
        icon={<DiscountIcon />}
      />
      {(productTypes || []).map((productType) => (
        <Level2Item
          key={productType.id}
          label={productType.name[lang]}
          icon={<CategoryIcon />}
          to={`/product/manage/${productType.id}`}
        />
      ))}
    </StyledLevel2>
  );
}
